.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  white-space:nowrap;
  padding: 0.375rem 0.75rem;
}

.secondary {
  background-color: var( --grayscale---gray-opaque);
  backdrop-filter: blur(5px);
}

.secondary-transparent {
  background-color: transparent;
  font-weight: 400;
  color: var(--grayscale---gray-60);
}

.secondary-bold {
  background-color: var(--grayscale---gray-10);
  font-weight: 600;
}

.ageGroupSelector {
  box-shadow: var(--shadow---button);
  padding: .75rem 1.5rem;
  cursor: pointer;
  border: 1px solid transparent;
}

.ageGroupSelectorActive {
  box-shadow: var(--shadow---button);
  padding: .75rem 1.5rem;
  cursor: pointer;
  background-color: var(--smooth---blue-light);
  border: 1px solid var(--base---blue);
}