.container {
  background-color: var(--grayscale---white);
  padding: 1.5rem;
  border: 1px solid var(--grayscale---gray-20);
  border-radius: 0.375rem;
}

.thumbnail {
  box-shadow: var(--shadow---label);
  border-radius: 0.625rem;
  height: 4.5rem;
  width: 4.5rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}