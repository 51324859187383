.container {
  min-height: 70vh;
  width: 100%;
  overflow-x: hidden;
}

.blur{
  filter: blur(.25rem);
  transition: all .5 ease;
}
