.flip {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.backgroundImage {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.background {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    overflow: hidden;
    border-radius: 25px;
    transform: translate(-50%, -50%);
}
