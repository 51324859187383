.container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.title {
  font-size: var(--bold---heading-2);
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: var(--subtitle-font-family);
}

.checkBox {
  font-size: var(--regular---caption-1) !important;
}

.message {
  background: var(--grayscale---white-80);
}

.subscription {
  max-width: 25ch;
}

.form-wrapper > input {
  padding: 0.6rem !important;
}

@media (max-width: 575px) {
  .checkBox {
    align-self: flex-start;
  }
}

.button-wrapper {
  display: flex;
  align-items: center;
}
