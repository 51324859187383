.container {
  position: absolute;
  bottom: 0;
  right: 1rem;
  width: 90%;
  max-width: 500px;
  background-color: var(--grayscale---white);
  transform: translateY(100%);
  border-radius: 0 0 1.25rem 1.25rem;
  box-shadow: var(--shadow---button);
}

.cartItems {
  overflow-y: auto;
  max-height: 60vh;
}

.loading {
  opacity: .5;
  pointer-events: none;
}