.footer {
  background-image: url('/images/footer_background.png');
  background-color: var(--smooth---yellow--duck);
  padding-bottom: 120px;
}

@media (max-width: 991px) {
  .footer,
  .subfooter {
    padding-bottom: 65px;
  }
}

.logo > img {
  max-width: 12rem;
}

@media (max-width: 991px) {
  .container {
    flex-direction: column;
  }
}

.item img {
  width: 2rem;
}

.item a {
  text-decoration: none;
  color: var(--grayscale---gray-80);
}

.item a:hover {
  color: var(--grayscale---black);
}

.subfooter {
  padding-top: 48px;
  padding-bottom: 90px;
}
