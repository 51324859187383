.shadow {
  box-shadow: 0 6px 16px rgba(0, 49, 57, 0.05);
}

.collapse {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 0;
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, 0);
}

.announcementBar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--bold---caption-1);
  color: var(--grayscale---gray-80);
  background-color: var(--smooth---green);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: 600;
}

.content {
  background-color: var(--grayscale---white-40);
  backdrop-filter: blur(44px);
  display: flex;
  padding: 1rem 5rem;
}

.navbarItems {
  font-family: var(--subtitle-font-family);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: var(--navbar-letter-spacing);
  white-space: nowrap;
  color: var(--grayscale---gray-80);
}

.navbarItems a {
  text-decoration: none;
  color: var(--grayscale---gray-80) !important;
}

.navbarItem a {
  text-decoration: none;
  color: var(--grayscale---black) !important;
}

.navbarItem:hover {
  color: var(--grayscale---black);
}

.language {
  font-weight: 600;
}

@media (max-width: 1200px) {
  .navbarItems {
    /* display: none; */
  }
}

.cartCount {
  display: inline-block;
  border-radius: 5rem;
  background-color: var(--base---blue);
  color: white;
  width: 1.3rem;
  height: 1.3rem;
  text-align: center;
  position: relative;
}

.cartCountInner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.9rem;
}

.link {
  display: block;
}
